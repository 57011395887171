import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClientModule } from '@angular/common/http';
import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatRippleModule,
  MatTableModule,
  MatDialogModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatTooltipModule,
  DateAdapter,
  MatProgressSpinnerModule,
  MatCardModule,
  MatInputModule,
  MatMenuModule,
} from '@angular/material';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
// import { LoginComponent } from './auth/login/login.component';
// import { RegistrationComponent } from './auth/registration/registration.component';
// import { InlineSVGModule } from 'ng-inline-svg';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { CustomDateAdapter } from './custom-date-adapter';

import { AvatarModule } from 'ngx-avatar';
// import { NgxCsvParserModule } from 'ngx-csv-parser';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { CalendarModule, DateAdapter as AngularCalendarDateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { AgmCoreModule } from '@agm/core';
// import { NgxChartsModule } from '@swimlane/ngx-charts';

import { InviteComponent } from './invite/invite.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent, NavBarComponent, InviteComponent],
  imports: [
    AppRoutingModule,
    AvatarModule,
    // NgxCsvParserModule,
    // CalendarModule.forRoot({
    //   provide: AngularCalendarDateAdapter,
    //   useFactory: adapterFactory
    // }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBa8IaoNVTApq_VIRtKq45Kl5RMhf_fcIc',
    //   libraries: ['places']
    // }),
    // InlineSVGModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    // AngularFireAuthGuardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatRippleModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    // FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    // MatGridListModule,
    MatProgressSpinnerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    // NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    // NgxChartsModule,
  ],
  entryComponents: [InviteComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Organisation } from '../interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {
  iframe = false;
  inviting = false;
  iframeError = false;
  awaitingResponse: boolean;
  organisationId: string;
  organisationDoc: AngularFirestoreDocument<Organisation>;
  organisation: Observable<Organisation>;
  inviteForm: FormGroup;
  availableVouchers: number;
  termsRows: number;
  separateDialCode = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // console.log('invite', );
    if (window.location !== window.parent.location) {
      // The page is in an iframe
      this.iframe = true;
    }
    this.inviteForm = this.fb.group({
      name: [, Validators.required],
      phone: [
        '+316',
        [
          Validators.required,
          Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$'),
          Validators.minLength(12),
          Validators.maxLength(12),
        ],
      ],
      terms: [, Validators.required],
    });
    this.route.params.subscribe((params) => {
      this.organisationId = params.organisationId;
      if (this.organisationId) {
        this.getInfo();
      } else {
        this.iframeError = true;
      }
    });
    if (this.router.url.includes('/invite/')) {
      console.log('inviting');
      this.inviting = true;
      this.inviteForm.addControl(
        'inviteSender',
        new FormControl('', Validators.required)
      );
    }
  }
  getError(name) {
    const field = this.inviteForm.get(name);

    let visibleName = '';
    if (name === 'postal') {
      visibleName = 'postcode';
    }
    if (name === 'phone') {
      visibleName = 'telefoonnummer';
    }
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      if (field.hasError('email')) {
        return 'Je moet een geldig e-mailadres invullen.';
      }
      if (name === 'phone') {
        if (field.hasError('pattern')) {
          return `Dit is geen geldig ${visibleName}`;
        }
        if (field.hasError('minlength') || field.hasError('maxlength')) {
          return `Dit is geen geldig ${visibleName}`;
        }
      }
      if (name === 'postal') {
        if (field.hasError('minlength') || field.hasError('maxlength')) {
          return `Vul je volledige ${visibleName} in.`;
        }
      }

      return '';
    }
  }
  getInfo() {
    this.organisationDoc = this.db.doc<Organisation>(
      'organisations/' + this.organisationId
    );
    this.organisation = this.organisationDoc.valueChanges();
    this.organisation = this.organisationDoc.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as Organisation;
        data.id = actions.payload.id;
        if (data.requiresFullName) {
          this.inviteForm.addControl(
            'surname',
            new FormControl('', Validators.required)
          );
        }
        if (data.requiresPostal) {
          this.inviteForm.addControl(
            'postal',
            new FormControl('', [
              Validators.required,
              Validators.minLength(6),
              Validators.maxLength(7),
            ])
          );
        }
        return { ...data };
      }),
      take(1)
    );
    this.organisation.subscribe((organisation) => {
      console.log('organisation', organisation);
    });
  }
  async submit() {
    if (!this.awaitingResponse) {
      this.awaitingResponse = true;
      const name = this.inviteForm.get('name')
        ? this.inviteForm.get('name').value
        : null;
      const phone = this.inviteForm.get('phone')
        ? this.inviteForm.get('phone').value
        : null;
      // let res: Observable<any>;

      const requestUrl = `${environment.functionsUrl}/httpsSignupForm`;
      // if (name) {
      //   requestUrl = requestUrl + `&name=${name}`;
      // }
      // if (phone) {
      //   requestUrl = requestUrl + `&phone=${phone}`;
      // }
      const postData = {
        organisationId: this.organisationId,
        phone,
        name,
      };
      if (this.inviting) {
        postData['inviteSender'] = this.inviteForm.get('inviteSender').value;
      }
      console.log('test', this.inviteForm.get('surname'));
      if (this.inviteForm.get('postal')) {
        let postal = this.inviteForm.get('postal').value as string;
        postData['postal'] = postal.toUpperCase().replace(/\s/g, '');
      }
      if (this.inviteForm.get('surname')) {
        postData['fullName'] = `${name} ${this.inviteForm.get('surname').value
          }`;
      }
      console.log('postData', postData);
      this.http.post(requestUrl, postData).subscribe((res) => {
        const result = res['status'] as string;
        this.inviteForm.reset();
        this.inviteForm.patchValue({
          phone: '+316',
        });
        this.awaitingResponse = undefined;
        console.log('result', result);
        if (result === 'success') {
          this.snackBar.open('Je aanmelding is ontvangen.', '', {
            duration: 5000,
          });
        } else if (result === 'error_creating_user') {
          this.snackBar.open(
            'Er is iets misgegaan met het aanmaken van je account, controleer je telefoonnummer.',
            '',
            {
              duration: 10000,
            }
          );
        } else {
          this.snackBar.open(
            'Een onbekende fout is opgetreden, neem contact op of probeer het later nog een keer.',
            '',
            {
              duration: 10000,
            }
          );
        }
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
// import {
//   redirectUnauthorizedTo,
//   redirectLoggedInTo
// } from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
// import { environment } from 'src/environments/environment';
import { InviteComponent } from './invite/invite.component';

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
// const redirectLoggedInToDefault = () => redirectLoggedInTo(['dashboardtownship']);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
const routes: Routes = [
  { path: '', component: InviteComponent },
  { path: 'signup/:organisationId', component: InviteComponent },
  { path: 'invite/:organisationId', component: InviteComponent },
];
// let routes: Routes = [];
// const allRoutes = {
//   default: {
//     path: '**',
//     component: ActivateVoucherComponent
//   },
//   activatevoucher: {
//     path: 'activate/:townshipId/:voucherGroupId',
//     component: ActivateVoucherComponent
//   },
//   // users: {
//   //   path: 'users',
//   //   component: UsersComponent,
//   //   canActivate: [AngularFireAuthGuard],
//   //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//   // },
//   // frequentAddress: {
//   //   path: 'frequentAddress',
//   //   component: FrequentAddressComponent,
//   // },
//   // audit: {
//   //   path: 'audit',
//   //   component: AuditComponent,
//   //   canActivate: [AngularFireAuthGuard],
//   //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//   // },
// // };
// console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// export class AppRoutingModule {
//   constructor(router: Router) {
//     const config = router.config;
//     environment.routes.forEach(route => {
//       config.push(allRoutes[route]);
//     });
//     // config.push({
//     //   path: '**',
//     //   redirectTo: 'dashboard-township',
//     //   canActivate: [AngularFireAuthGuard],
//     //   data: { authGuardPipe: redirectUnauthorizedToLogin },
//     // });
//     router.resetConfig(config);
//     console.log('router3', router);
//   }
// }

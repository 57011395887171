export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCI1ZtYEz3myRupKXwkgZ_f-pLthoC752k',
    authDomain: 'hallo-bot.firebaseapp.com',
    databaseURL: 'https://hallo-bot.firebaseio.com',
    projectId: 'hallo-bot',
    storageBucket: 'hallo-bot.appspot.com',
    messagingSenderId: '82103981851',
    appId: '1:82103981851:web:8cc527dbf1280df8b025be',
  },
  functionsUrl: 'https://us-central1-hallo-bot.cloudfunctions.net',
  name: {
    name: 'Uitnodiging',
  },
  routes: ['invite'],
};
